import { Media } from '@canalplus/dive';
import { DIMENSIONS } from '@canalplus/mycanal-commons';
import { IBackgroundParameters } from '@canalplus/mycanal-sdk';
import { Template } from '@canalplus/sdk-hodor';
import ThumborMediaImage from '../../../../../components/ThumborMediaImage/ThumborMediaImage';
import { Video } from '../../../../../components/Video';
import styles from './MediaLayer.css';

type MediaLayerProps = {
  isSmallScreenWidth: boolean;
  isTvDevice: boolean;
  backgroundParameters: IBackgroundParameters;
  isVideoEnabled: boolean;
};

export default function MediaLayer({
  isSmallScreenWidth,
  isTvDevice,
  backgroundParameters,
  isVideoEnabled,
}: MediaLayerProps): JSX.Element {
  const coverImage = (
    <Media
      image={
        <ThumborMediaImage
          alt=""
          url={backgroundParameters?.URLImageOptimizedRegular}
          urlMobile={backgroundParameters?.URLImageOptimizedCompact}
          dimensions={{
            default: DIMENSIONS.OFFER_HIGHLIGHT.image,
          }}
        />
      }
    />
  );

  const { URLVideoOptimizedCompact = '', URLVideoOptimizedRegular = '' } = backgroundParameters || {};
  const videoUrl =
    (isSmallScreenWidth && URLVideoOptimizedCompact) || !URLVideoOptimizedRegular
      ? URLVideoOptimizedCompact
      : URLVideoOptimizedRegular;

  return (
    <div className={styles.mediaLayer}>
      {videoUrl && isVideoEnabled ? (
        <Video
          cover={coverImage}
          from={Template.Showcase}
          isVisible={isVideoEnabled}
          isLoop
          isMuted={!isTvDevice}
          hasFullFrameIndicator={isTvDevice}
          videoContent={{
            url: videoUrl,
            encryption: 'clear',
            format: 'mp4',
          }}
        />
      ) : (
        coverImage
      )}
    </div>
  );
}
