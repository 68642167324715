import { cva } from 'class-variance-authority';

export const linkCVA = cva(['font-dt-font-family-system font-dt-font-weight-semibold group'], {
  variants: {
    isReversed: {
      true: 'text-dt-theme-link-link-default',
      false: 'text-dt-theme-link-link-accent',
    },
    isDisabled: { true: 'opacity-dt-opacity-50 cursor-not-allowed' },
    hasIcon: {
      true: 'flex items-center no-underline',
      false: 'underline',
    },
    size: {
      small: 'text-dt-font-size-12 leading-dt-font-line-height-16',
      medium: 'text-dt-font-size-14 leading-dt-font-line-height-18',
      large: 'text-dt-font-size-16 leading-dt-font-line-height-20',
    },
    iconPosition: {
      left: undefined,
      right: undefined,
    },
  },
  compoundVariants: [
    {
      isDisabled: false,
      isReversed: true,
      className:
        'group-hover:text-dt-theme-link-link-hover group-active:text-dt-theme-link-link-pressed group-active:decoration-dt-theme-link-link-pressed',
    },
    {
      isDisabled: false,
      isReversed: false,
      className: 'group-hover:text-dt-theme-link-link-accent-pressed group-active:text-dt-theme-link-link-accent-hover',
    },
    {
      hasIcon: true,
      iconPosition: 'left',
      className: 'flex-row-reverse justify-end',
    },
  ],
});

export const linkIconCVA = cva('', {
  variants: {
    isDisabled: {
      true: 'opacity-dt-opacity-50 cursor-not-allowed',
    },
    isReversed: {
      true: 'fill-dt-theme-icon-link-link-icon',
      false: 'fill-dt-theme-icon-link-link-icon-accent',
    },
    size: {
      small: 'size-16',
      medium: 'size-18',
      large: 'size-20',
    },
  },
  compoundVariants: [
    {
      isDisabled: false,
      isReversed: true,
      className:
        'group-hover:fill-dt-theme-icon-link-link-icon-hover group-active:fill-dt-theme-icon-link-link-icon-pressed',
    },
    {
      isDisabled: false,
      isReversed: false,
      className:
        'group-hover:fill-dt-theme-icon-link-link-icon-accent-pressed group-active:fill-dt-theme-icon-link-link-icon-accent-hover',
    },
  ],
});
