import {
  ChannelsGrid,
  ImageWall,
  OfferHighlight as OfferHighlightLegacy,
  ProgramsHighlight,
  ReboundProduct,
  ShowcaseImage,
  Tiles,
} from '@canalplus/mycanal-sharedcomponent';
import { Binder } from '@canalplus/one-navigation';
import { Template } from '@canalplus/sdk-hodor';
import { ApiV2OnClick, type ApiV2Context } from '@dce-front/hodor-types/api/v2/common/dto/definitions';
import classNames from 'classnames';
import { ReactNode, memo, useMemo } from 'react';
import { useSelector } from 'react-redux';
import LoadableBanner from '../../../components/Banner/LoadableBanner';
import Linker from '../../../components/Linker/Linker';
import LoadablePlainTextHtmlTemplate from '../../../components/PlainTextHtml/LoadablePlainTextHtmlTemplate';
import ButtonLayer from '../../../components/Showcase/ButtonLayer/ButtonLayer';
import Separator from '../../../components/Showcase/Separator/Separator';
import Tabs from '../../../components/Showcase/Tabs/Tabs';
import TextLayer from '../../../components/Showcase/TextLayer/TextLayer';
import { ShowcaseTypes } from '../../../constants/showcase';
import { getLocationStateContext } from '../../../helpers/contents/contents-helper';
import { MIDDLEWARE_SHOWCASE, MIDDLEWARE_SHOWCASE_TOP } from '../../../helpers/oneNavigation/middleware';
import I18n from '../../../lang';
import {
  featDisplayHeaderMenuSelector,
  getFeatureToggleOfferHighlightMinimalPlayer,
} from '../../../store/slices/application-selectors';
import LoadableContentRowStandard from '../../ContentRowStandard';
import { OfferHighlight } from './OfferHighlight/OfferHighlight';
import styles from './Showcase.css';

// Removes invalid strates from states array to prevent crashing
function filterInvalidStrates(strate: any) {
  return (
    strate &&
    (strate?.type !== ShowcaseTypes.OFFERHIGHLIGHT ||
      strate?.type !== ShowcaseTypes.BRANDDISCOVER ||
      strate?.contents?.length)
  );
}

export type ShowcaseProps = {
  areAnimationsAutoplay: boolean;
  strates?: any;
  isSmallScreenWidth: boolean;
  offerLocation: string;
  isTvDevice: boolean;
};

// The Template has to match each strate to a component
function Showcase({
  areAnimationsAutoplay,
  isSmallScreenWidth,
  isTvDevice,
  offerLocation,
  strates,
}: ShowcaseProps): JSX.Element {
  const isFirstStrateOfferHighlight =
    strates && (strates[0]?.type === ShowcaseTypes.OFFERHIGHLIGHT || strates[0]?.type === ShowcaseTypes.BRANDDISCOVER);
  const featDisplayHeaderMenu = useSelector(featDisplayHeaderMenuSelector);
  const isFeatOfferHighlightMinimalPlayer = useSelector(getFeatureToggleOfferHighlightMinimalPlayer);

  const hasStrates = strates?.length;

  const { t } = I18n.useTranslation();

  const offerHighLightLabelPlayPauseBtn = {
    pauseBtn: t('ShowcaseTemplate.OfferHighlight.pauseBtn'),
    playBtn: t('ShowcaseTemplate.OfferHighlight.playBtn'),
  };

  const renderWrapperLinker = (
    onClick: ApiV2OnClick,
    children: ReactNode,
    id: string,
    className?: string,
    trackingContext?: ApiV2Context
  ) => (
    <Linker
      className={className}
      data={{
        mainOnClick: { ...onClick, trackingContext },
        context: getLocationStateContext(onClick.displayTemplate),
      }}
      objKey="onClick"
      id={id}
    >
      {children}
    </Linker>
  );

  return (
    <div
      className={classNames(styles.showcase, {
        [styles['showcase--withHeader']]: isFirstStrateOfferHighlight && featDisplayHeaderMenu,
      })}
    >
      {useMemo(
        () =>
          hasStrates && (
            <section className={classNames(styles.showcase__strates)}>
              {strates.filter(filterInvalidStrates).map((strate, index) => {
                // We need a stable key for showcase strates because when there is more than
                // 10s between SSR fetch and client hydrate, react-query refetchs showcase content.
                // In this case strate.hash is updated randomly, this causes an undesired
                // unmount / remount that causes a lost of focus
                const hash = isTvDevice ? index : strate?.hash;

                const middleware = index === 0 ? MIDDLEWARE_SHOWCASE_TOP : MIDDLEWARE_SHOWCASE;

                switch (strate?.type) {
                  case ShowcaseTypes.TEXT:
                    return <TextLayer contents={strate.contents} key={hash} />;

                  case ShowcaseTypes.BUTTON:
                    return (
                      <Binder middleware={middleware} key={hash}>
                        <ButtonLayer contents={strate.contents} id={strate.hash} />
                      </Binder>
                    );

                  case ShowcaseTypes.CHANNELSGRID:
                    return (
                      <ChannelsGrid
                        contents={strate.contents}
                        description={strate.description}
                        key={hash}
                        title={strate.title}
                        isTvDevice={isTvDevice}
                      />
                    );

                  case ShowcaseTypes.OFFERHIGHLIGHT:
                  case ShowcaseTypes.BRANDDISCOVER:
                    if (!strate.contents.length) {
                      return null;
                    }
                    return (
                      <Binder
                        middleware={middleware}
                        enabled={strate?.contents.length > 0 && strate.contents[0]?.buttons}
                        key={hash}
                      >
                        {isFeatOfferHighlightMinimalPlayer ? (
                          <OfferHighlight
                            contents={strate?.contents}
                            isTvDevice={isTvDevice}
                            isSmallScreenWidth={isSmallScreenWidth}
                            isVideoEnabled={areAnimationsAutoplay}
                            trackingContext={strate.context}
                          />
                        ) : (
                          <OfferHighlightLegacy
                            contents={strate.contents}
                            isSmallScreenWidth={isSmallScreenWidth}
                            isTvDevice={isTvDevice}
                            isVideoDisabled={!areAnimationsAutoplay}
                            key={hash}
                            trackingContext={strate.context}
                            labelPlayPauseBtn={offerHighLightLabelPlayPauseBtn}
                            Linker={Linker}
                          />
                        )}
                      </Binder>
                    );

                  case ShowcaseTypes.PROGRAMSHIGHLIGHT:
                    return (
                      <Binder middleware={middleware} key={hash} enabled={strate.contents[0]}>
                        <ProgramsHighlight
                          content={strate.contents[0]}
                          isTvDevice={isTvDevice}
                          Linker={Linker}
                          trackingContext={strate.context}
                          renderContentImageLinker={renderWrapperLinker}
                        />
                      </Binder>
                    );

                  case ShowcaseTypes.TABS:
                    return (
                      <Binder key={hash} middleware={middleware}>
                        <Tabs contents={strate.contents} tabs={strate.tabs} offerLocation={offerLocation} />
                      </Binder>
                    );

                  case ShowcaseTypes.TILES:
                    return (
                      <Binder key={hash} middleware={middleware}>
                        <Tiles
                          contents={strate.contents}
                          isTvDevice={isTvDevice}
                          Linker={Linker}
                          title={strate.title}
                        />
                      </Binder>
                    );

                  case ShowcaseTypes.IMAGE:
                    return (
                      <Binder key={hash} middleware={middleware}>
                        <ShowcaseImage
                          button={strate.buttons?.[0]}
                          content={strate.contents[0]}
                          description={strate.description}
                          focusStyle={styles.showcase__focusStyle}
                          isTvDevice={isTvDevice}
                          Linker={Linker}
                          title={strate.title}
                        />
                      </Binder>
                    );

                  case ShowcaseTypes.SEPARATOR:
                    return <Separator key={hash} />;

                  case ShowcaseTypes.CONTENTROW: {
                    return strate.contents ? (
                      <LoadableContentRowStandard
                        contents={strate.contents}
                        displayParameters={strate?.displayParameters}
                        from={Template.Showcase}
                        header={{ title: strate?.title, subtitle: strate?.subtitle, button: strate?.button }}
                        key={hash}
                        URLNextPage={strate?.paging?.URLPage}
                        middleware={middleware}
                      />
                    ) : null;
                  }
                  case ShowcaseTypes.PLAINTEXTHTML:
                    return (
                      <LoadablePlainTextHtmlTemplate
                        title={strate.title}
                        html={strate.html}
                        style={strate.css}
                        script={strate.js}
                        key={hash}
                      />
                    );

                  case ShowcaseTypes.REBOUNDPRODUCT:
                    return (
                      <Binder key={hash} middleware={middleware} enabled={!!strate.button}>
                        <ReboundProduct
                          button={strate.button}
                          title={strate.contents[0]?.title}
                          urlLogo={strate.contents[0]?.URLLogo}
                          altLogo={strate.contents[0]?.altLogo}
                          trackingContext={strate.context}
                          Linker={Linker}
                        />
                      </Binder>
                    );

                  case ShowcaseTypes.BANNER:
                    return (
                      <Binder middleware={middleware} key={hash}>
                        <LoadableBanner contents={strate.contents} from={Template.Showcase} />
                      </Binder>
                    );

                  case ShowcaseTypes.WALL:
                    return (
                      <Binder key={hash} middleware={middleware}>
                        <ImageWall
                          button={strate.button}
                          contents={strate.contents}
                          description={strate.description}
                          title={strate.title}
                          subtitle={strate.subtitle}
                          isTvDevice={isTvDevice}
                          renderContentCardLinker={renderWrapperLinker}
                          titleDisplayMode={strate?.displayParameters?.titleDisplayMode}
                          Linker={Linker}
                          trackingContext={strate.context}
                        />
                      </Binder>
                    );

                  default:
                    return null;
                }
              })}
            </section>
          ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [strates, isSmallScreenWidth, areAnimationsAutoplay]
      )}
    </div>
  );
}

export default memo(Showcase);
