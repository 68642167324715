import classNames from 'classnames';
import { MaterialChevronRightSvg } from '../../icons/icons';
import { DiveIconPosition } from '../../types/Dive.types';
import { linkCVA, linkIconCVA } from './Link.cva';
import { LinkProps, LinkSize } from './Link.types';

export const LINK_TARGET_BLANK_REL = 'noreferrer';

/**
 * A Link component that renders an anchor tag with predefined styles.
 * It supports hover, active, and disabled states for better user interaction.
 *
 * @example
 * <Link href="https://example.com">Visit Example</Link>
 */
export function Link({
  id,
  href,
  children,
  size = LinkSize.Large,
  hasIcon = false,
  isDisabled = false,
  isReversed = false,
  iconPosition = DiveIconPosition.Right,
  className,
  'data-testid': dataTestId,
  ...props
}: LinkProps): JSX.Element {
  return (
    <a
      data-testid={dataTestId}
      id={id}
      aria-label={typeof children === 'string' ? children : undefined}
      className={classNames(
        linkCVA({
          isReversed,
          isDisabled,
          hasIcon,
          iconPosition,
          size,
        }),
        className
      )}
      {...props}
      {...(isDisabled
        ? { 'aria-disabled': 'true', onClick: undefined }
        : { href, rel: props.target === '_blank' ? LINK_TARGET_BLANK_REL : undefined })}
    >
      {children}
      {hasIcon && (
        <MaterialChevronRightSvg
          aria-hidden="true"
          className={linkIconCVA({
            isDisabled,
            isReversed,
            size,
          })}
        />
      )}
    </a>
  );
}
